import 'core-js/stable';

// Nx 设置的 core-js 版本无法自动导入 es2022+ 的 API
import 'core-js/stable/';
import 'core-js/stable/array/at';
import 'core-js/stable/array/find-last';
import 'core-js/stable/global-this';

import 'abortcontroller-polyfill/dist/polyfill-patch-fetch';

import smoothscroll from 'smoothscroll-polyfill';
// 开启 polyfill, 避免 react-virtuoso 内部调用 scrollBy 时报错
smoothscroll.polyfill();
